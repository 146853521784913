import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IZabkaContestValues } from '../formik/zabka-contest-form.config';
import { IZabkaContestWinnerValues } from '../formik/zabka-contest-form-winner.config';

export const zabkaContestFormMutation = (data: IZabkaContestValues) => {
    return axios.post(prependApiUrl('/klasyka-gatunku/create'), {
        ...data,
    });
};

export const postZabkaContestWinner = (createForm: IZabkaContestWinnerValues) => {
    return axios.post(prependApiUrl('/klasyka-gatunku/addresses'), {
        ...createForm,
    });
};

export const getZabkaContestCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/klasyka-gatunku/check/${token}`), {});
};
