import React from 'react';

import {
    layout,
    container,
    contact,
    content,
    header,
    hasHeader,
    thanks,
    headerContent,
    mainLayout,
} from './zabka-contest-layout.module.scss';
import { grid } from '../styles/grid.module.scss';
import staticFiles from '../config/static-files';

import MainLayout, { IMainLayoutProps } from './main-layout';
import ContestContact from '../components/organisms/contest-contact';
import ContestThankYou, { IContestThankYouProps } from '../components/organisms/contest-thank-you';

interface IZabkaContestLayout extends IMainLayoutProps {
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
    layoutClass?: string;
    bannerClass?: string;
}

const ZabkaContestLayout: React.FC<IZabkaContestLayout> = ({
    children,
    className = '',
    layoutClass = '',
    bannerClass,
    headerProps,
    ...rest
}) => {
    return (
        <MainLayout
            {...rest}
            className={`${layout} ${headerProps ? hasHeader : ''} ${layoutClass}`}
            containerClassName={mainLayout}
            bannerClass={bannerClass}
        >
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    {headerProps && <ContestThankYou {...headerProps} className={thanks} />}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                <div className={`${className} ${content}`}>{children}</div>
                <ContestContact
                    className={contact}
                    regulationsUrl={staticFiles.zabkaContestRegulations}
                />
            </div>
        </MainLayout>
    );
};

export default ZabkaContestLayout;
