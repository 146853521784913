import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import { container, img, info } from './zabka-contest-prize.module.scss';

const prizeImg = '../../assets/images/zabka-contest/prizes.png';

interface IZabkaContestPrizeProps {
    className?: string;
}

const ZabkaContestPrize: React.FC<IZabkaContestPrizeProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <div className={img}>
                <StaticImage src={prizeImg} alt="" />
            </div>
            <p className={info}>
                {t('zabka.contest.prize.info.1')}
                <strong>{t('zabka.contest.prize.info.2')}</strong>
                {t('zabka.contest.prize.info.3')}
                <strong>{t('zabka.contest.prize.info.4')}</strong>
                {t('zabka.contest.prize.info.5')}
                <br />
                {t('zabka.contest.prize.info.6')}
            </p>
        </div>
    );
};

export default ZabkaContestPrize;
