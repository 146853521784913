import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    box,
    label,
    title,
    content,
    link,
    divider,
    img,
    left,
    right,
} from './contest-contact.module.scss';

interface ISummerContestContactProps {
    className?: string;
    regulationsUrl: string;
}

const leftImg = '../../assets/images/zabka-contest/footer-left.png';
const rightImg = '../../assets/images/zabka-contest/footer-right.png';

const SummerContestContact: React.FC<ISummerContestContactProps> = ({
    className = '',
    regulationsUrl,
}) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <div className={`${img} ${left} left-img`}>
                <StaticImage src={leftImg} alt="" />
            </div>
            <h2 className={title}>{t('contest.contact.title')}</h2>
            <div className={content}>
                <div className={box}>
                    <p className={label}>{t('contest.contact.email.label')}</p>
                    <a href={`mailto:${t('contest.contact.email')}`}>
                        {t('contest.contact.email')}
                    </a>
                </div>
                <span className={divider} />
                <div className={box}>
                    <p className={label}>{t('contest.contact.regulations.label')}</p>
                    <p>
                        <span>{t('contest.contact.regulations.1')}</span>{' '}
                        <a
                            className={link}
                            href={regulationsUrl}
                            target={'_blank'}
                            rel="noreferrer noopener"
                        >
                            {t('contest.contact.regulations.2')}
                        </a>{' '}
                        <span>{t('contest.contact.regulations.3')}</span>
                    </p>
                </div>
            </div>
            <div className={`${img} ${right} right-img`}>
                <StaticImage src={rightImg} alt="" />
            </div>
        </div>
    );
};

export default SummerContestContact;
