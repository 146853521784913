import React from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

import { container, form, layout } from './zabka-contest-winner-page.module.scss';
import { IPage } from '../models/page.model';

import ZabkaContestLayout from '../layouts/zabka-contest-layout';
import ZabkaContestWinnerForm from '../components/organisms/zabka-contest-winner-form';

interface IZabkaContestWinnerPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const ZabkaContestWinnerPage: React.FC<IZabkaContestWinnerPageProps> = ({ data }) => {
    const { page } = data;
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ZabkaContestLayout page={page} className={container} layoutClass={layout}>
                <ZabkaContestWinnerForm className={form} />
            </ZabkaContestLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ZabkaContestWinnerPage;
