// extracted by mini-css-extract-plugin
export var box = "contest-contact-module--box--68474";
export var container = "contest-contact-module--container--9f084";
export var content = "contest-contact-module--content--6e84b";
export var divider = "contest-contact-module--divider--f689b";
export var errorBlink = "contest-contact-module--error-blink--ee48d";
export var img = "contest-contact-module--img--2568d";
export var label = "contest-contact-module--label--6b049";
export var left = "contest-contact-module--left--18f49";
export var link = "contest-contact-module--link--3481d";
export var right = "contest-contact-module--right--00ac9";
export var title = "contest-contact-module--title--ad75b";